<template>
  <validation-observer ref="formPrivPol">
    <form
      @submit.prevent="save"
    >
      <div class="animated fadeIn">
        <b-card class="px-md-2">
          <ResponseAlert ref="response" />
          <h3>{{ $route.meta.breadcrumb[1].text }}</h3>
          <b-row class="mt-2">
            <b-col
              md="12"
            >
              <b-row
                class="my-1"
              >
                <b-col sm="12">
                  <label
                    class="h5"
                    for="title"
                  >Judul</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Judul"
                    rules="required"
                  >
                    <b-form-input
                      id="title"
                      v-model="models.title"
                      type="text"
                      :disabled="isDetailPage"
                      :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row
                class="my-1"
              >
                <b-col sm="12">
                  <label
                    class="h5"
                    for="content"
                  >Deskripsi</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Deskripsi"
                    rules="required"
                  >
                    <quill-editor
                      id="content"
                      v-model="models.content"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-center justify-content-md-end mt-1">
            <b-button
              type="button"
              variant="light"
              class="btn-min-width rounded"
              @click="$router.push({path: config.uri})"
            >
              {{ isDetailPage ? 'Kembali' : 'Batal' }}
            </b-button>
            <b-button
              v-if="!isDetailPage"
              type="submit"
              variant="primary"
              class="btn-min-width rounded ml-1"
            >
              Simpan
            </b-button>
          </div>
        </b-card>
      </div>
    </form>
  </validation-observer>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import api from '@/utils/api'
import {
  BCard, BRow, BCol, BButton, BFormInput,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  name: 'FormPrivacyPolicy',
  metaInfo: {
    title: 'Form Privacy Policy',
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      config: {
        uri: `/${this.$route.meta.link}`,
      },
      models: {
        type: 1,
        title: '',
        content: '',
      },
      required,
    }
  },
  created() {
    this.getById()
  },
  methods: {
    getById() {
      this.$axios.get(`${api.pageContent}?type=${this.models.type}`)
        .then(res => {
          const { data } = res.data
          const content = data.rows[0]
          this.models.title = content ? content.title : ''
          this.models.content = content ? content.content : ''
        }).catch(() => this.$router.push({
          name: 'page404',
        }))
    },
    save() {
      this.$refs.formPrivPol.validate().then(async success => {
        if (success) {
          this.$axios.post(api.pageContent, this.models)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Success update Privacy Policy',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              this.$router.push(this.config.uri)
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        } else {
          window.scrollTo(0, 0)
        }
      })
    },
  },
}
</script>

<style>

</style>
